<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div>

        <b-card>
            <h2>Export Toko</h2>
            <div class="form">
                <div class="form-group">
                    <label for="item_uuid">Pilih Toko</label>
                    <v-select id="item_uuid" v-model="exportPayload.merchant_uuid" :options="optionsMerchant"
                        :reduce="item => item.uuid" label="name" placeholder="Type to search item..."
                        @search="onSearchMerchant">
                        <template slot="no-options">
                            Type here to search merchant...
                        </template>
                    </v-select>
                </div>
                <div v-if="isLoadingExport">
                    <br>
                    <b-spinner class="mb-2" variant="primary" /><br>
                </div>
                <button v-else class="btn btn-primary btn-sm" @click="exportByMerchant()"
                    :disabled="exportPayload.merchant_uuid == null">
                    Export Data
                </button>
                <br>

            </div>
        </b-card>

        <b-card>
            <h2>Import Toko</h2>
            <div class="form">
                <div class="form-group">
                    <label for="">File:</label><br>
                    <input id="fileImport" ref="fileImport" type="file" @change="changeImportFile()">
                </div>
                <div class="form-group">
                    <label for="item_uuid">Pilih Toko</label>
                    <v-select id="item_uuid" v-model="importPayload.merchant_uuid" :options="optionsMerchant"
                        :reduce="item => item.uuid" label="name" placeholder="Type to search item..."
                        @search="onSearchMerchant">
                        <template slot="no-options">
                            Type here to search merchant...
                        </template>
                    </v-select>
                </div>
                <div v-if="isLoadingImport">
                    <br>
                    <b-spinner class="mb-2" variant="primary" /><br>
                </div>
                <button v-else class="btn btn-primary btn-sm" @click="importFile()"
                    :disabled="importPayload.merchant_uuid == null">
                    Import Data
                </button>
                <button class="ml-1 btn btn-primary btn-sm" @click="$bvModal.show('import-modal')">
                    History Import
                </button>
                <br>
                <hr>
                <!-- <small>Download import format <a
                        href="/import-format/format-import-merchant-product.xlsx">here</a></small> -->
                <div class="alert alert-info p-1 mt-1">
                    <strong>Catatan: Wajib diisi</strong>
                    <ul>
                        <li>Satuan</li>
                        <li>Brand</li>
                        <li>Harga Jual Standar</li>
                        <li>Lokasi Sku</li>
                        <li>Stok Aktual</li>
                        <li>Spesifikasi</li>
                        <li>Pakai format berikut: <a href="/import-format/format-import-merchant-product.xlsx">Download
                                disini</a>
                        </li>
                    </ul>
                </div>
            </div>
        </b-card>
        <b-modal id="import-modal" no-close-on-backdrop size="xl" title="Import History" hide-footer
            @show="openedModal">
            <div>
                <table class="table b-table table-fixed">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Merchant</th>
                            <th>File Import</th>
                            <th>Status</th>
                            <th>Import Time</th>
                            <th>Error Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="5" class="text-center">
                                <b-spinner label="Loading..." variant="primary" />
                            </td>
                        </tr>
                        <tr v-for="(item, index) in resultHistory.data" v-if="!isLoading" :key="index">
                            <td>{{ item.title || '-' }}</td>
                            <td>{{ item.merchant.name }}</td>
                            <td><a :href="item.file_path">Download
                                    File</a></td>
                            <td>{{ item.status }}</td>
                            <td>{{ item.created_at }}</td>
                            <td>{{ item.error_message || '-' }}</td>
                        </tr>
                        <tr v-if="result.total == 0 && !isLoading">
                            <td colspan="5" class="text-center">
                                Data is empty.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row mt-1">
                    <div class="col-6 pl-2">
                        <small>Showing {{ resultHistory.from }} to {{ resultHistory.to }} from {{ resultHistory.total
                            }}</small>
                    </div>
                    <div class="col-6 pr-2">
                        <pagination :data="resultHistory" :limit="4" align="right"
                            @pagination-change-page="getHistory" />
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
    BFormGroup,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import VueBarcode from 'vue-barcode'
import FileSaver from 'file-saver'
import axios from 'axios'
import vSelect from 'vue-select'
import { StreamBarcodeReader } from 'vue-barcode-reader'

import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import moment from 'moment'

Vue.use(VuejsDialog)

export default {
    title() {
        return 'SKU Master'
    },
    components: {
        BFormGroup,
        BCard,
        BButton,
        BCardText,
        BCardCode,
        BDropdown,
        BDropdownItem,
        BSpinner,
        VueBarcode,
        vSelect,
        StreamBarcodeReader,
    },
    watch: {
        filter: {
            handler: _.debounce(function () {
                this.getData()
            }, 300),
            deep: true,
        },
        checkAll: {
            handler(value) {
                if (value) {
                    this.exportFilter.uuids = this.result.data.map(obj => obj.uuid)
                } else {
                    this.exportFilter.uuids = []
                }
            },
            immediate: true,
        },
    },
    setup(props) {
        return {
            checkPermission,
            successNotification,
            errorNotification,
        }
    },
    data() {
        return {
            checkAll: false,
            currentPage: 1,
            result: Object,
            isLoading: false,
            isLoadingImport: false,
            isLoadingPrintBarcode: false,
            isLoadingExport: false,
            formPayload: Object,
            filter: {
                search: '',
            },
            exportFilter: {
                uuids: [],
            },
            importPayload: {
                file: null,
                merchant_uuid: null
            },
            exportPayload: {
                merchant_uuid: null
            },
            validations: {},
            merchants: [],
            optionsMerchant: [],
            resultHistory: Object
        }
    },
    created() {
        this.getData()
        this.getMerchants()
        this.getHistory()
        // this.getItem()
        // this.getBrand()
        // this.getUom()

    },
    mounted() {
    },
    methods: {
        openedModal() {
            // this.getHistory()
        },
        getHistory(page = 1) {
            this.isLoading = true
            const queryParams = this.filter
            queryParams.page = page
            queryParams.per_page = 5

            this.$http.get('super-admin/merchant-products/import-history', {
                params: queryParams,
            })
                .then(response => {
                    this.resultHistory = response.data.data
                    this.isLoading = false
                })
        },
        clearImportExportPayload() {
            this.importPayload = {
                file: null,
                merchant_uuid: null
            }
        },
        changeImportFile() {
            // eslint-disable-next-line prefer-destructuring
            this.importPayload.file = this.$refs.fileImport.files[0]
        },
        downloadImportFormat() {

        },
        importFile() {
            this.isLoadingImport = true

            const form = new FormData()
            // eslint-disable-next-line no-restricted-syntax
            for (const key in this.importPayload) {
                if (this.importPayload.hasOwnProperty(key) && this.importPayload[key] != null) {
                    form.append(key, this.importPayload[key])
                }
            }
            this.$http.post('/super-admin/merchant-products/import', form, {
                headers: { 'content-type': 'multipart/form-data' },
            })
                .then(response => {
                    successNotification(this, 'Success', 'Produk sedang di import di background')
                    this.isLoadingImport = false
                    this.getData()
                    this.clearImportExportPayload()
                    this.$bvModal.hide('import-modal')
                })
                .catch(error => {
                    if (error.response.data.meta.messages.length > 0) {
                        errorNotification(this, 'Oops!', error.response.data.meta.messages)
                    }
                    this.isLoadingImport = false
                })
        },
        exportByMerchant(type = 'excel') {
            this.isLoadingExport = true

            const form = new FormData()
            // eslint-disable-next-line no-restricted-syntax
            for (const key in this.exportPayload) {
                if (this.exportPayload.hasOwnProperty(key) && this.exportPayload[key] != null) {
                    form.append(key, this.exportPayload[key])
                }
            }
            this.$http.post('/super-admin/merchant-products/export', form, {
                headers: { 'content-type': 'multipart/form-data' },
                responseType: type == 'view' ? '' : 'blob',
            })
                .then(response => {
                    var timestamp = Math.floor(Date.now() / 1000)

                    this.exportPayload = {
                        merchant_uuid: null
                    }

                    this.isLoadingExport = false
                    this.$bvModal.hide('export-modal')

                    if (type == 'view') {
                        this.contentHtml = response.data.data.html
                    } else {
                        // var timestamp = Math.floor(Date.now() / 1000)
                        const date = moment().format('DDMMYYYY')
                        if (type == 'excel') {
                            var fileName = `export-sku-toko-${date}.xlsx`
                            FileSaver.saveAs(response.data, fileName)
                        }

                        if (type == 'pdf') {
                            const blob = new Blob([response.data], { type: 'application/pdf' })
                            var fileName = `export-sku-toko-${date}.pdf`
                            FileSaver.saveAs(blob, fileName)
                        }
                    }
                })
                .catch(error => {
                    if (error.response.data.meta.messages.length > 0) {
                        errorNotification(this, 'Oops!', error.response.data.meta.messages)
                    }
                    this.isLoadingExport = false
                })
        },
        onSearchMerchant(search, loading) {
            if (search.length) {
                loading(true)
                this.searchMerchant(loading, search, this)
            }
        },
        searchMerchant: _.debounce((loading, search, vm) => {
            vm.$http.get(`super-admin/merchant?per_page=10&search=${encodeURI(search)}`)
                .then(response => {
                    vm.optionsMerchant = response.data.data.data.map(obj => ({
                        uuid: obj.uuid,
                        name: obj.name,
                    }))
                })
                .catch(error => {
                    console.error(error)
                })
            loading(false)
        }, 300),
        getMerchants() {

            this.$http.get('/super-admin/merchant')
                .then(response => {
                    this.optionsMerchant = response.data.data.data.map(obj => ({
                        uuid: obj.uuid,
                        name: obj.name,
                    }))
                }).catch((error) => {
                    console.log(error);
                })
        },
        getData(page = 1) {
            this.isLoading = true
            this.currentPage = page
            const queryParams = this.filter
            queryParams.page = this.currentPage
            queryParams.product_type = 'default'

            this.$http.get('/super-admin/product', {
                params: queryParams,
            })
                .then(response => {
                    this.result = response.data.data
                    this.isLoading = false
                })
        },
        preparePayload() {
            const form = new FormData()

            // eslint-disable-next-line no-restricted-syntax
            for (const key in this.formPayload) {
                if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
                    form.append(key, this.formPayload[key])
                }
            }

            return form
        },
        deleteItem(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'Are you sure to delete this item?',
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`super-admin/product/${id}`)
                        .then(response => {
                            this.getData(this.currentPage)
                            this.$swal({
                                icon: 'success',
                                title: 'Success!',
                                text: 'Item successfully deleted',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            })
                        })
                        .catch(error => {
                            if (error.response.data.meta.messages.length > 0) {
                                errorNotification(this, 'Oops!', error.response.data.meta.messages)
                            }
                        })
                }
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
